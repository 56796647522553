import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ConsultarContainer } from './Consultar.styles';
import SearchIcon from '../../Components/Images/Consultar/search-icon.svg';
import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function Consultar() {
  const location = useLocation();
  const [activeCard, setActiveCard] = useState(null);
  const [cpf, setCpf] = useState('');
  const [protocolos, setProtocolos] = useState([]);
  const [protocoloDetails, setProtocoloDetails] = useState(null);
  const [email, setEmail] = useState('');

  const { codProtocolo } = location.state || { codProtocolo: '' };
  const { emailCliente } = location.state || { emailCliente: '' };

  {console.log(location)}
  useEffect(() => {
    if (codProtocolo) {
      axios.get(`${API_BASE_URL}/api/protocol/id/${codProtocolo}`, { withCredentials: true })
        .then(response => {
          setProtocoloDetails(response.data);
        })
        .catch(error => console.error('Error al obtener los detalles del número de caso:', error));
    }
  }, [codProtocolo]);

  useEffect(() => {
    if (emailCliente) {
      setCpf(formatCpf(emailCliente)); // Adicionado para definir o valor do input de pesquisa
      axios.get(`${API_BASE_URL}/api/protocol/email/${emailCliente}`, { withCredentials: true })
        .then(response => {
          const data = response.data;
          const formattedData = data.map((item) => ({
            id: item.cod_protocolo,
            numero: item.numero_protocolo,
            status: item.status,
            nome: item.nome_cliente,
            /* cpf: formatCpf(item.documento_cliente), */
            email: item.email_cliente,
            class: item.status === 'Aberto' ? 'open' : 'complete',
          }));
          setProtocolos(formattedData);
        })
        .catch(error => console.error('Error:', error));
    }
  }, [emailCliente]);


  const handleCardClick = (index, protocoloId) => {
    setActiveCard(index);
    axios.get(`${API_BASE_URL}/api/protocol/id/${protocoloId}`, { withCredentials: true })
      .then(response => {
        setProtocoloDetails(response.data);
      })
      .catch(error => console.error('Error al obtener los detalles del número de caso:', error));
  };

  const handleSearch = () => {
    setProtocoloDetails('');
    //const cleanCpf = cpf.replace(/[^\dKk]/g, ''); // Remove caracteres especiais
    //if (cleanCpf) {
      axios.get(`${API_BASE_URL}/api/protocol/email/${email}`, { withCredentials: true })
        .then(response => {
          const data = response.data;
          const formattedData = data.map((item) => {
            let statusClass;
            switch (item.status) {
              case 'Aberto':
                statusClass = 'open';
                break;
              case 'Finalizado':
                statusClass = 'complete';
                break;
              case 'Expirado':
                statusClass = 'expired';
                break;
              default:
                statusClass = 'unknown'; // ou qualquer outro valor padrão que você preferir
            }

            return {
              id: item.cod_protocolo,
              numero: item.numero_protocolo,
              status: item.status,
              nome: item.nome_cliente,
              /* cpf: formatCpf(item.documento_cliente), */
              email: item.email_cliente,
              class: statusClass,
            };
          });
          setProtocolos(formattedData);
        })
        .catch(error => console.error('Error:', error));
    //}
  };

  const formatCpf = (value) => {
    // Remove todos os caracteres que não são números ou K/k
    let formattedValue = value.replace(/[^\dKk]/g, '');

    // Formata o valor conforme os padrões do CPF
    if (formattedValue.length > 2) {
      formattedValue = `${formattedValue.slice(0, 2)}.${formattedValue.slice(2)}`;
    }
    if (formattedValue.length > 6) {
      formattedValue = `${formattedValue.slice(0, 6)}.${formattedValue.slice(6)}`;
    }
    if (formattedValue.length > 10) {
      formattedValue = `${formattedValue.slice(0, 10)}-${formattedValue.slice(10)}`;
    }

    return formattedValue.toUpperCase();
  };

/*    const handleCpfChange = (e) => {
    setCpf(formatCpf(e.target.value));
  }; */

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('pt-BR', options);
  };

  const isRepresentante = protocoloDetails &&
    (protocoloDetails.nome_cliente !== protocoloDetails.nome_representante ||
      protocoloDetails.documento_cliente !== protocoloDetails.documento_representante);

  return (
    <ConsultarContainer>
      <div className='left-panel'>
        <div className='search-protocol'>
          <input
            type="text"
            placeholder='Ingrese el e-mail del titular'
            value={email}
            /* maxLength={12} */
            onChange={handleEmailChange}
          />
          <img
            src={SearchIcon}
            alt="search-icon"
            onClick={handleSearch}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <br />
        <div className='left-panel-protocols-div'>
          <h3>Lista de número de caso</h3>
          <br />
          {protocolos.length === 0 ? (
            <div>Use el campo de búsqueda para obtener la lista de número de caso</div>
          ) : protocolos.length === 0 && cpf ? (
            <div>No se encontraron número de caso</div>
          ) : (
            protocolos.map((protocolo, index) => (
              <div
                key={protocolo.id}
                className={`protocolos-card ${(activeCard === index || (activeCard === null && protocolo.id === codProtocolo)) ? 'active' : ''}`}
                onClick={() => handleCardClick(index, protocolo.id)}
              >
                <div className='upper-part'>
                  <div>
                    <strong>Nº de caso</strong>
                    <span>{protocolo.numero}</span>
                  </div>
                  <span className={`badge ${protocolo.status === 'Aberto' ? 'open' : protocolo.status === 'Finalizado' ? 'complete' : 'expired'}`}>
                    {protocolo.status === 'Aberto' ? 'Abierto' : protocolo.status === 'Finalizado' ? 'Finalizado' : 'Expirado'}
                  </span>
                </div>
                <br />
                <div className='bottom-part'>
                  <strong>Nome: </strong>
                  <span>{protocolo.nome}</span>
                  {/* <span>RUT {protocolo.cpf}</span> */}
                  {/* <span>E-mail: {protocolo.email_cliente}</span>
                  {console.log(protocolo)} */}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className='right-panel'>
        {protocoloDetails && (
          <div>
            <div>
              <h2>Información del número de caso</h2>
            </div>
            <br />
            <div className='button-div'>
              {/* <button className='button'>Reenviar</button> */}
            </div>
            <br />
            <div className='separator-block'>
              <div>
                <strong>Nº de caso</strong>
                <span>{protocoloDetails.numero_protocolo}</span>
              </div>
              <div>
                <strong>Estado</strong>
                <span className={`badge ${protocoloDetails.status === 'Aberto' ? 'open' : protocoloDetails.status === 'Finalizado' ? 'complete' : 'expired'}`}>
                  {protocoloDetails.status === 'Aberto' ? 'Abierto' : protocoloDetails.status === 'Finalizado' ? 'Finalizado' : 'Expirado'}
                </span>
              </div>
              <div>
                <span><strong>Abierto el día:</strong> {new Date(protocoloDetails.data_criacao).toLocaleDateString()}</span>
                <span><strong>Válido hasta:</strong> {new Date(protocoloDetails.data_validade_protocolo).toLocaleDateString()}</span>
                <span><strong>Última actualización:</strong> {new Date(protocoloDetails.data_alteracao).toLocaleDateString()}</span>
              </div>
            </div>
            <div className='separator-block2'>
              <h3>Titular de los productos</h3>
              <div className='separator'>
                <div>
                  <strong>Nombre del titular del producto</strong>
                  <span>{protocoloDetails.nome_cliente}</span>
                </div>
                <div>
                  <strong>E-mail del titular</strong>
                  <span>{protocoloDetails.email_cliente}</span>
                  {/* <strong>RUT del titular</strong>
                  <span>{formatCpf(protocoloDetails.documento_cliente)}</span> */}
                </div>
              </div>
            </div>
            <div className='separator-block3'>
              <h3>Información de contacto para la retirada del producto</h3>
              {isRepresentante ? (
                <>
                  <span>El <strong>representante</strong> hará la retirada del producto</span>
                  <div className='separator'>
                    <div>
                      <strong>Nombre del representante</strong>
                      <span>{protocoloDetails.nome_representante}</span>
                      <strong>Celular del representante</strong>
                      <span>{protocoloDetails.telefone_representante}</span>
                    </div>
                    <div>
                      {/* <strong>RUT del representante</strong>
                      <span>{formatCpf(protocoloDetails.documento_representante)}</span> */}
                      <strong>E-mail del representante</strong>
                      <span>{protocoloDetails.email_representante}</span>
                      <strong>Código Postal del representante</strong>
                      <span>{protocoloDetails.zip_code_representante}</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <span>El <strong>titular</strong> hará la retirada del producto</span>
                  <div className='separator'>
                    <div>
                      <strong>Nombre del titular</strong>
                      <span>{protocoloDetails.nome_cliente}</span>
                      <strong>Celular del titular</strong>
                      <span>{protocoloDetails.telefone_cliente}</span>
                    </div>
                    <div>
                      {/* <strong>RUT del titular</strong>
                      <span>{formatCpf(protocoloDetails.documento_cliente)}</span> */}
                      <strong>E-mail del titular</strong>
                      <span>{protocoloDetails.email_cliente}</span>
                      <strong>Código Postal del titular</strong>
                      <span>{protocoloDetails.zip_code_cliente}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className='separator-block4'>
              <h3>Productos para intercambio</h3>
              {protocoloDetails.produtos.map((produto, index) => (
                <div className='separator' key={index}>
                  <div>
                    <strong>Tipo de producto</strong>
                    <span>{produto.nome}</span>
                  </div>
                  <div>
                    <i><img src={produto.url_imagem} alt={produto.nome} /></i>
                    <span className={`badge ${produto.status === 'Aberto' ? 'open' : produto.status === 'Finalizado' ? 'complete' : 'expired'}`}>
                      {produto.status === 'Aberto' ? 'Abierto' : produto.status === 'Finalizado' ? 'Finalizado' : 'Expirado'}
                    </span>
                  </div>
                  <div>
                    <strong>Motivo del intercambio</strong>
                    <span>{produto.motivo}</span>
                  </div>
                </div>
              ))}
            </div>
            {protocoloDetails.historico && protocoloDetails.historico.length > 0 && (
              <div className='separator-block5'>
                <h3>Historial de intercambios</h3>
                {protocoloDetails.historico.map((troca, index) => (
                  <div key={index} className='separator'>
                    <h4>Intercambio {index + 1}</h4>
                    <div>
                      <strong>Fecha del intercambio </strong>
                      <span>{formatDate(troca['Data_Troca'])}</span>
                    </div>
                    <br />
                    <div className='separator2'>
                      <h4>Productos intercambiados</h4>
                      {troca.itens_troca && troca.itens_troca.length > 0 ? (
                        troca.itens_troca.map((item, itemIndex) => {
                          const produto = protocoloDetails.produtos.find(p => p.cod_item === item.cod_item);
                          return (
                            <div key={itemIndex} className='div-separator'>
                              <div>
                                <strong>Tipo de producto</strong>
                                <span>{produto ? produto.nome : "Desconocido"}</span>
                              </div>
                              <div>
                                <strong>Número de serie</strong>
                                <span>{item.numero_serie}</span>
                              </div>
                              <div>
                                <strong>Lote</strong>
                                <span>{item.numero_lote}</span>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div>No hay productos intercambiados.</div>
                      )}
                    </div>
                    <br />
                    <div className='separator3'>
                      <h4>Datos de la Farmacia</h4>
                      <div className='div-separator1'>
                        <div>
                          <strong>Bandera</strong>
                          <span>{troca.Bandeira}</span>
                        </div>
                        <div>
                          <strong>ID de la sucursal</strong>
                          <span>{troca.id_filial}</span>
                        </div>
                        <div>
                          <strong>Dirección</strong>
                          <span>{troca.endereco}</span>
                          <span>{troca.cidade}, {troca.estado} - {troca.zipcode}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </ConsultarContainer>
  );
}
