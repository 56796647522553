import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import { DispositivosContainer } from './Dispositivos.styles';
import PlusIcon from '../../Components/Images/Dispositivos/plus-icon.svg';
import { useAuth } from '../../AuthContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function Dispositivos() {
  const navigate = useNavigate();
  const [produtos, setProdutos] = useState([]);
  const [produtosApi, setProdutosApi] = useState([]);
  const [motivosApi, setMotivosApi] = useState([]);
  const [loading, setLoading] = useState(true);

  const { currentStep, setCurrentStep } = useAuth();

  useEffect(() => {
    if (currentStep < 1) {
      navigate('/protocolo');
    } else {
      setCurrentStep(2);
    }
  }, [currentStep, setCurrentStep, navigate]);

  useEffect(() => {
    const fetchProdutos = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/product/1`, { withCredentials: true });
        setProdutosApi(response.data.Produtos);
      } catch (error) {
        console.error('Errorr al recuperar productos API', error);
      }
    };

    const fetchMotivos = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/reasons/`, { withCredentials: true });
        setMotivosApi(response.data.Motivos);
      } catch (error) {
        console.error('Error al recuperar motivos API', error);
      }
    };

    const loadApis = async () => {
      await Promise.all([fetchProdutos(), fetchMotivos()]);
      setLoading(false);
    };

    loadApis();

    const savedProtocolo = localStorage.getItem('protocoloJson');
    if (savedProtocolo) {
      const protocoloData = JSON.parse(savedProtocolo);
      if (protocoloData.produtos) {
        setProdutos(protocoloData.produtos);
      }
    }
  }, []);

  function VoltarParaProtocolo() {
    navigate('/protocolo');
  }

  function AvançarParaContato() {
    const savedProtocolo = localStorage.getItem('protocoloJson');
    let protocoloData = savedProtocolo ? JSON.parse(savedProtocolo) : {};

    protocoloData.produtos = produtos.map(produto => {
      const produtoApi = produtosApi.find(p => p.nome === produto.produto);
      return {
        ...produto,
        url_imagem: produtoApi ? produtoApi.url_imagem : null
      };
    });

    localStorage.setItem('protocoloJson', JSON.stringify(protocoloData));
    navigate('/contato');
  }

  function adicionarProduto() {
    setProdutos([...produtos, { frontendId: Date.now(), produto: '', motivo: '', motivoId: '', id: null }]);
  }

  function removerProduto(frontendId) {
    const updatedProdutos = produtos.filter(produto => produto.frontendId !== frontendId);
    setProdutos(updatedProdutos);

    const protocoloData = JSON.parse(localStorage.getItem('protocoloJson')) || {};
    protocoloData.produtos = updatedProdutos;
    localStorage.setItem('protocoloJson', JSON.stringify(protocoloData));
  }

  function atualizarProduto(frontendId, field, value) {
    const updatedProdutos = produtos.map(produto => {
      if (produto.frontendId === frontendId) {
        let updatedProduto = { ...produto, [field]: value };

        if (field === 'produto') {
          const selectedProduto = produtosApi.find(p => p.nome === value);
          if (selectedProduto) {
            updatedProduto = {
              ...updatedProduto,
              id: selectedProduto.cod_dispositivo,
              motivo: '',
              motivoId: ''
            };
          }
        }

        if (field === 'motivo') {
          const selectedMotivo = motivosApi.find(m => m.nome === value);
          if (selectedMotivo) {
            updatedProduto = { ...updatedProduto, motivoId: selectedMotivo.cod_motivo, motivo: selectedMotivo.nome };
          }
        }

        return updatedProduto;
      }
      return produto;
    });
    setProdutos(updatedProdutos);
  }

  function todosCamposPreenchidos() {
    return produtos.every(produto =>
      produto.produto && produto.motivo && produto.id && produto.motivoId
    );
  }

  const isAvancarDisabled = !todosCamposPreenchidos() || produtos.length === 0;

  const produtoOptions = produtosApi.map(produto => ({
    value: produto.nome,
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={produto.url_imagem} alt={produto.nome} style={{ width: '20px', height: '20px', marginRight: '10px' }} />
        {produto.nome}
      </div>
    ),
    key: produto.cod_dispositivo
  }));

  function getMotivoOptions(codDispositivo) {
    const produto = produtosApi.find(p => p.cod_dispositivo === codDispositivo);
    if (produto) {
      const motivoIds = JSON.parse(produto.motivos);
      return motivosApi
        .filter(motivo => motivoIds.includes(motivo.cod_motivo))
        .map(motivo => ({
          value: motivo.nome,
          label: motivo.nome,
          key: motivo.cod_motivo
        }));
    }
    return [];
  }

  if (loading) {
    return <DispositivosContainer><div>Carregando...</div></DispositivosContainer>;
  }

  return (
    <>
      <DispositivosContainer>
        <div className="page-content start-protocol">
          <h2>Crear número de caso de intercambio</h2>
          <h3 className="protocol-step">Paso 2/4: Agregar productos para intercambio</h3>
          <br />
          <div className='buttons-div'>
            <div className='buttons-div-left'>
              <button className='button-terciary' onClick={adicionarProduto}>
                <img src={PlusIcon} alt="plus-icon" />
                <span>Agregar producto</span>
              </button>
            </div>
            <div className='buttons-div-right'>
              <button className='button-secondary' onClick={VoltarParaProtocolo}>Volver</button>
              <button
                className={`button ${isAvancarDisabled ? 'disabled' : ''}`}
                onClick={AvançarParaContato}
                disabled={isAvancarDisabled}
              >
                Avanzar
              </button>
            </div>
          </div>
          <br />
          <div>
            <h3>Productos agregados al número de caso.</h3>
            <br />
            {produtos.length === 0 ? (
              <p>No fueron agregados los productos al número de caso.</p>
            ) : (
              produtos.map(produto => (
                <div className='produto-card' key={produto.frontendId}>
                  <div>
                    <strong>Producto</strong>
                    <Select
                      name="produto"
                      id="produto-dropdown"
                      className='dropdown'
                      value={{ value: produto.produto, label: produto.produto }}
                      onChange={(selectedOption) => atualizarProduto(produto.frontendId, 'produto', selectedOption.value)}
                      options={produtoOptions}
                    />
                  </div>
                  <div>
                    <strong>Motivo del cambio</strong>
                    <Select
                      name="motivo"
                      id="motivo-dropdown"
                      className='dropdown'
                      value={{ value: produto.motivo, label: produto.motivo }}
                      onChange={(selectedOption) => atualizarProduto(produto.frontendId, 'motivo', selectedOption.value)}
                      options={getMotivoOptions(produto.id)}
                    />
                  </div>
                  <div>
                    <button className='button-tiny' onClick={() => removerProduto(produto.frontendId)}>X</button>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </DispositivosContainer>
    </>
  );
}
