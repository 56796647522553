import React, { useState, useEffect } from 'react';
import { ProtocoloContainer } from './Protocolo.styles';
import '../../index.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../../AuthContext';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function Protocolo() {
  const navigate = useNavigate();
  const [documento, setDocumento] = useState('');
  const [protocolo, setProtocolo] = useState('');
  const [codProtocolo, setCodProtocolo] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [celular, setCelular] = useState('');
  const [errors, setErrors] = useState([]);
  const [protocolStatus, setProtocolStatus] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isAdvanceDisabled, setIsAdvanceDisabled] = useState(true);
  //const [isDocumentoValid, setIsDocumentoValid] = useState(true);

  const { currentStep, setCurrentStep } = useAuth();

  useEffect(() => {
    if (currentStep < 0) {
      navigate('/menu');
    } else {
      setCurrentStep(1);
    }
  }, [currentStep, setCurrentStep, navigate]);

  useEffect(() => {
    const savedProtocolo = localStorage.getItem('protocoloJson');
    if (savedProtocolo) {
      const protocoloData = JSON.parse(savedProtocolo);
      setProtocolo(protocoloData.protocolo);
      setNome(protocoloData.nome);
      setEmail(protocoloData.email);
      setCelular(protocoloData.celular);
    }
  }, []);

  const handleValidation = () => {
    const newErrors = [];
    if (!protocolo) newErrors.push('Nº de protocolo/Nº de caso');
    if (!nome) newErrors.push('Nombre del titular del producto');
    //if (!documento) newErrors.push('Documento titular del producto');
    if (!email) newErrors.push('Correo electrónico del propietario del producto');
    if (!celular) newErrors.push('Teléfono celular del titular del producto');

    setErrors(newErrors);

    if (newErrors.length === 0) {
      const existingData = localStorage.getItem('protocoloJson');
      const existingProtocoloData = existingData ? JSON.parse(existingData) : {};

      const updatedProtocoloData = {
        ...existingProtocoloData,
        //documento,
        protocolo,
        nome,
        email,
        celular,
      };

      localStorage.setItem('protocoloJson', JSON.stringify(updatedProtocoloData));
      navigate('/dispositivos');
    }
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handlePhoneChange = (e) => {
    const cleanedPhone = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
    setCelular(cleanedPhone);
  };

  useEffect(() => {
    const checkProtocolExistence = async () => {
      if (protocolo.length === 8) {
        try {
          const response = await axios.get(`${API_BASE_URL}/api/protocol/${protocolo}`, { withCredentials: true });
          if (response.data && response.data[0]) {
            setProtocolStatus(response.data[0].status);
            setIsModalVisible(true);
            setCodProtocolo(response.data[0].cod_protocolo);
            setDocumento(response.data[0].documento_cliente);
  
            // Desabilita o botão se o protocolo já existe e está em um estado bloqueado
            if (response.data[0].status === 'Aberto' || response.data[0].status === 'Finalizado') {
              setIsAdvanceDisabled(true);
            } else {
              setIsAdvanceDisabled(false);
            }
          } else {
            setProtocolStatus(null);
            setIsModalVisible(false);
            setIsAdvanceDisabled(false); // Protocolo não existe, habilitar botão
          }
        } catch (error) {
          setProtocolStatus(null);
          setIsModalVisible(false);
          setIsAdvanceDisabled(false); // Erro na requisição, mas habilita o botão para continuar
        }
      } else {
        setProtocolStatus(null);
        setIsAdvanceDisabled(true); // Protocolo incompleto, desabilitar botão
      }
    };
  
    checkProtocolExistence();
  }, [protocolo]);

  useEffect(() => {
    const validateFields = () => {
      const emailValid = validateEmail(email);
      const allFieldsFilled = protocolo.length === 8 && nome && email && celular;
      const isProtocolFree = protocolStatus === null; // Protocolo não existe
  
      setIsAdvanceDisabled(!(allFieldsFilled && emailValid && isProtocolFree));
    };
  
    validateFields();
  }, [protocolo, nome, email, celular, protocolStatus]);

  return (
    <>
      <ProtocoloContainer>
        <div className="page-content start-protocol">
          <h2>Crear numero de caso de intercambio</h2>
          <h3 className="protocol-step">Paso 1/4: Identificación del titular</h3>

          <div className="row">
            <div className="col-xs-6 col-md-4">
              {isModalVisible && (
                <div className='modal-yellow'>
                  <div className='modal-yellow-text'>
                    <strong>Atención </strong>Este numero de caso ya existe en el estado:
                    <a
                      className="btn-link"
                      onClick={() => navigate(`/consultar/`, { state: { codProtocolo, documento } })}
                    >
                      <strong> {protocolStatus}</strong>
                    </a>
                  </div>
                  <div className='modal-yellow-icon' onClick={handleCloseModal}>
                    <span>x</span>
                  </div>
                </div>
              )}
              <br />
              <div className="input-group protocol_number-group">
                <label className='bold' htmlFor="protocol_number">Nº de caso</label>
                <br />
                <input
                  id="protocol_number"
                  maxLength="8"
                  type="text"
                  className="form-control"
                  placeholder="00000000"
                  onChange={(e) => setProtocolo(e.target.value.replace(/\D/g, ''))} // Apenas números
                  value={protocolo}
                />
                <p className="text-muted">8 numeros</p>
              </div>
              <div className="input-group customer_name-group">
                <label className='bold' htmlFor="customer_name">Nombre del titular del producto</label>
                <br />
                <input
                  id="customer_name"
                  type="text"
                  className="form-control"
                  placeholder=""
                  maxLength="100"
                  onChange={(e) => setNome(e.target.value)}
                  value={nome}
                />
              </div>
              <div className="input-group customer_email-group">
                <label className='bold' htmlFor="customer_email">Correo eletrónico del propietario del producto</label>
                <br />
                <input
                  id="customer_email"
                  type="text"
                  className="form-control"
                  placeholder=""
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                {!validateEmail(email) && email && <span className="text-danger">Correo eletrónico inválido</span>}
              </div>
              <div className="input-group customer_cellphone-group">
                <label className='bold' htmlFor="customer_cellphone">Teléfono móvil del titular</label>
                <br />
                <input
                  id="customer_cellphone"
                  type="text"
                  className="form-control"
                  maxLength={12}
                  placeholder='56 9 XXXX XXXX o 56 2 XXX XXX XXXX'
                  onChange={handlePhoneChange}
                  value={celular}
                />
              </div>
              <div className="input-group">
                <button
                  type="submit"
                  className={`btn btn-primary ${isAdvanceDisabled ? 'disabled' : ''}`}
                  onClick={handleValidation}
                  disabled={isAdvanceDisabled}
                >
                  Avanzar
                </button>
              </div>
            </div>
            <div className="col-xs-6 col-md-4">
              {errors.length > 0 && (
                <div id="message-box" className="alert alert-danger">
                  <strong>Atención</strong> Por favor complete los siguientes campos:
                  <ul>
                    {errors.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </ProtocoloContainer>
    </>
  );
}
