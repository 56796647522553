import styled from "styled-components";

export const DispositivosContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px;
    margin-bottom: 85px;

    .buttons-div {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .buttons-div-right {
            display: flex;
            flex-wrap: wrap;
            grid-gap: 25px;
        }
    }

    .produto-card {
        display: flex;
        align-items: end;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 25px;
    }

    .button {
        padding: 6px 12px;
        font-size: 14px;
        /* width: 80px; */
        background-color: #281E78;
        color: #ffffff;
        border-radius: 4px;
        cursor: pointer;
    }

    .button:hover {
        background-color: #1a144f;
    }

    .button.disabled {
        cursor: not-allowed;
        background-color: #818181;
    }

    .button-secondary {
        padding: 6px 12px;
        font-size: 14px;
        /* width: 80px; */
        border: 1px solid #281E78;
        background-color: #ffffff;
        color: #281E78;
        border-radius: 4px;
        cursor: pointer;
    }

    .button-secondary:hover {
        background-color: #e6e6e6;
    }

    .button-terciary {
        padding: 6px 12px;
        font-size: 14px;
        border: 1px solid #281E78;
        background-color: #ffffff;
        color: #281E78;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .button-terciary:hover {
        background-color: #e6e6e6; 
    }

    .button-tiny-div {
        display: flex;
    }

    .button-tiny {
        padding: 6px 12px;
        font-size: 14px;
        color: #333;
        background-color: #fff;
        border: 1px solid #ccc;
        outline: none;
        cursor: pointer;
        height: 36px;
    }

    .form-control {
        display: block;
        height: 34px;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.428571429;
        color: #555555;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        outline: none;
    }

    .dropdown {
        width: 200px;
        z-index: 9999;
    }
`;