import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { ResumoContainer } from './Resumo.styles';
import { useAuth } from '../../AuthContext';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function Resumo() {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState('initial');
    const [protocoloData, setProtocoloData] = useState(null);
    const [retiradaInfo, setRetiradaInfo] = useState({});

    const { currentStep } = useAuth();
    useEffect(() => {
        if (currentStep < 3) {
            navigate('/contato');
        }
    }, [currentStep, navigate]);

    useEffect(() => {
        const savedProtocolo = localStorage.getItem('protocoloJson');
        if (savedProtocolo) {
            const data = JSON.parse(savedProtocolo);
            setProtocoloData(data);

            const representante = data.representante && data.representante.length > 0 ? data.representante[0] : null;
            if (representante) {
                setRetiradaInfo({
                    nome: representante.nome,
                    //documento: representante.documento,
                    email: representante.email,
                    celular: representante.celular,
                    cep: representante.zipcode
                });
            } else {
                setRetiradaInfo({
                    nome: data.nome,
                    //documento: data.documento,
                    email: data.email,
                    celular: data.celular,
                    cep: data.zipcode
                });
            }
        }
    }, []);

    function Voltar() {
        navigate('/contato');
    }

    function EnviarProtocolo() {
        setModalVisible(true);
        setModalContent('initial');
    }

    function removeFormatting(str) {
        return typeof str === 'string' ? str.replace(/[\.\-\s]/g, '') : str;
    }

    function formatProtocoloData(data) {
        const formattedData = {
            ...data,
            //documento: removeFormatting(data.documento),
            celular: removeFormatting(data.celular),
            zipcode: removeFormatting(data.zipcode),
            representante: data.representante ? data.representante.map(rep => ({
                ...rep,
                //documento: removeFormatting(rep.documento),
                celular: removeFormatting(rep.celular),
                zipcode: removeFormatting(rep.zipcode)
            })) : []
        };

        return formattedData;
    }

    async function handleSendProtocolo() {
        // Descriptografar o userId
        const encryptedUserId = localStorage.getItem('userId');
        let decryptedUserId = null;
        if (encryptedUserId) {
            decryptedUserId = CryptoJS.AES.decrypt(encryptedUserId, SECRET_KEY).toString(CryptoJS.enc.Utf8);
        }
    
        // Adicionar userId ao JSON de protocolo
        let updatedProtocoloData = {
            ...protocoloData,
            userId: decryptedUserId
        };
    
        // Formatar os dados do protocolo
        updatedProtocoloData = formatProtocoloData(updatedProtocoloData);
    
        try {
            const response = await axios.post(`${API_BASE_URL}/api/protocol`, updatedProtocoloData, {
                withCredentials: true
            });
    
            if (response.status === 201) {
                localStorage.removeItem('protocoloJson');
                setModalContent('confirmation');
            } else {
                console.error('Error al enviar número de caso:', response);
            }
        } catch (error) {
            console.error('Error al enviar número de caso:', error);
        }
    }
    

    function handleFinish() {
        navigate('/menu');
    }

    if (!protocoloData) {
        return <ResumoContainer><div>Cargando...</div></ResumoContainer>;
    }

    return (
        <>
            <ResumoContainer>
                <div className="page-content start-protocol">
                    <h2>Crear número de caso de intercambio</h2>
                    <h3 className="protocol-step">Paso 4/4: Resumo</h3>
                    <br />
                    <div className='separator-block-div'>
                        <div className='separator-block'>
                            <div>
                                <strong>Número de caso: </strong>
                                <span>{protocoloData.protocolo}</span>
                            </div>
                            <br />
                            <h3>Datos del titular</h3>
                            <br />
                            <div className='separator-div'>
                                <div>
                                    <strong>Nombre del titular del producto</strong>
                                    <span>{protocoloData.nome}</span>
                                </div>
                                {/* <div>
                                    <strong>RUT del titular del producto</strong>
                                    <span>{protocoloData.documento}</span>
                                </div> */}
                                <div>
                                    <strong>Correo electrónico del propietario del producto</strong>
                                    <span>{protocoloData.email}</span>
                                </div>
                                <div>
                                    <strong>Teléfono móvil del titular del producto.</strong>
                                    <span>{protocoloData.celular}</span>
                                </div>
                            </div>
                        </div>
                        <div className='separator-block'>
                            <h3>Productos añadidos al número de caso</h3>
                            <br />
                            {protocoloData.produtos.map((produto) => (
                                <div className='separator-div separator-product' key={produto.frontendId}>
                                    <div>
                                        <img src={produto.url_imagem} alt={produto.produto} />
                                    </div>
                                    <div>
                                        <strong>Producto</strong>
                                        <span>{produto.produto}</span>
                                    </div>
                                    <div>
                                        <strong>Motivo</strong>
                                        <span>{produto.motivo}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='separator-block'>
                            <h3>Información de retiro</h3>
                            <br />
                            <div className='separator-div2'>
                                <div>
                                    <strong>Nombre de la persona que recoge el producto</strong>
                                    <span>{retiradaInfo.nome}</span>
                                </div>
                               {/*  <div>
                                    <strong>RUT para retiro</strong>
                                    <span>{retiradaInfo.documento}</span>
                                </div> */}
                                <div>
                                    <strong>Correo electrónico</strong>
                                    <span>{retiradaInfo.email}</span>
                                </div>
                                <div>
                                    <strong>Teléfono móvil</strong>
                                    <span>{retiradaInfo.celular}</span>
                                </div>
                                <div>
                                    <strong>Codigo postal</strong>
                                    <span>{retiradaInfo.cep}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='buttons-div'>
                        <button className='button-secondary' onClick={Voltar}>Regresar</button>
                        <button className='button' onClick={EnviarProtocolo}>Enviar número de caso</button>
                    </div>
                </div>
                {modalVisible && (
                    <>
                        <div className="overlay"></div>
                        <div className="modal">
                            <div className="modal-content">
                                {modalContent === 'initial' ? (
                                    <>
                                        <div className="modal-header">
                                            <h4 className="modal-title">Enviar número de caso</h4>
                                        </div>
                                        <br />
                                        <div className="modal-body">
                                            <p>¿Realmente quieres enviar este número de caso?</p>
                                        </div>
                                        <br />
                                        <div className="modal-footer">
                                            <button id="edit_btn" type="button" className="button-secondary" onClick={() => navigate(`/protocolo`)}>Editar información</button>
                                            <button id="send_btn" type="button" className="button" onClick={handleSendProtocolo}>Enviar número de caso</button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="modal-header">
                                            <h4 className="modal-title">
                                                <span className="glyphicon glyphicon-ok-circle" aria-hidden="true"></span> Terminado
                                            </h4>
                                        </div>
                                        <br />
                                        <div className="modal-body">
                                            <p>El número de caso de intercambio se generó correctamente y se envió la información para el retiro de los nuevos productos a los contactos informados.</p>
                                        </div>
                                        <br />
                                        <div className="modal-footer">
                                            <button id="finish_btn" type="button" className="button" onClick={handleFinish}>Entender y terminar</button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </ResumoContainer>
        </>
    );
}
